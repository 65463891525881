'use client';
import useClickOutside from '@/shared/hooks/useClickOutside';
import { Portal } from '@/shared/ui/Portal/Portal';
import {
  flip,
  offset,
  Placement,
  shift,
  useFloating,
} from '@floating-ui/react-dom';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import mergeRefs from 'merge-refs';
import {
  CSSProperties,
  forwardRef,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

export type PopoverProps = {
  children: ReactNode;
  render: ReactNode;
  variant?: 'hover' | 'click';
  placement?: Placement;
  className?: string;
  innerClassName?: string;
  styleButton?: CSSProperties;
  isOpen: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  closeOnClickOutside?: boolean;
  buttonClassName?: string;
  style?: CSSProperties;
  offset?: [number, number];
};

export interface PopoverRef {
  referenceElement: HTMLDivElement | null;
  popperElement: HTMLDivElement | null;
}

export const Popover = forwardRef<PopoverRef, PopoverProps>(
  (
    {
      render,
      children,
      styleButton,
      placement = 'bottom-end',
      buttonClassName,
      innerClassName,
      onOpen,
      onClose,
      isOpen,
      className,
      variant = 'click',
      closeOnClickOutside = true,
      style,
      offset: offsetValue = [0, 4],
    },
    ref
  ) => {
    const [referenceElement, setReferenceElement] =
      useState<HTMLDivElement | null>(null);

    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
      null
    );
    const refBtn = useRef<HTMLDivElement>(null);
    const popupRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(
      ref,
      () => ({
        referenceElement,
        popperElement,
      }),
      [referenceElement, popperElement]
    );

    const onEnter = variant == 'click' ? undefined : onOpen;
    const onLeave = variant == 'click' ? undefined : onClose;
    const onClick = variant == 'hover' ? undefined : isOpen ? onClose : onOpen;

    useClickOutside(refBtn, [popupRef.current], (e) => {
      if (isOpen && closeOnClickOutside) {
        e.stopPropagation();
        onClose?.();
      }
    });

    const { x, y, refs, strategy } = useFloating({
      placement,
      middleware: [
        offset({
          crossAxis: offsetValue[0],
          mainAxis: offsetValue[1],
        }),
        flip(),
        shift(),
      ],
    });

    const setReference = useCallback(
      (node: HTMLDivElement | null) => {
        setReferenceElement(node);
        refs.setReference(node);
      },
      [refs]
    );

    const setFloating = useCallback(
      (node: HTMLDivElement | null) => {
        setPopperElement(node);
        refs.setFloating(node);
      },
      [refs]
    );

    return (
      <>
        <div
          onMouseEnter={onEnter}
          onMouseLeave={onLeave}
          onClick={onClick}
          style={styleButton}
          className={clsx(buttonClassName, 'cursor-pointer')}
          ref={mergeRefs(refBtn, setReference) as any}>
          {children}
        </div>

        <AnimatePresence>
          {isOpen && (
            <Portal>
              <motion.div
                ref={mergeRefs(popupRef, setFloating) as any}
                id={'tooltip'}
                className={clsx(className, 'z-[30]')}
                initial={{ opacity: 0 }}
                exit={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
                style={{
                  ...style,
                  position: strategy,
                  top: y ?? 0,
                  left: x ?? 0,
                }}
                onMouseLeave={onLeave}>
                <div className={clsx(innerClassName)}>{render}</div>
              </motion.div>
            </Portal>
          )}
        </AnimatePresence>
      </>
    );
  }
);

Popover.displayName = 'Popover';
